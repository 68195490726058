import React from 'react';

// MUI
import { IconButton, styled } from '@mui/material';

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  "& svg": {
    width: "1.0rem",
    height: "1.0rem",
  },
  "&:hover": {
    backgroundColor: "#e1cee8",
  },
}));

export default function CardIconButton({children, ...rest}) {
  return (
    <IconButtonStyled color="iconCardButton" size='small' {...rest}>
      {children}
    </IconButtonStyled>
  )
};