import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Assets
import logo from '../../../assets/logo.webp';
import bg from '../../../assets/bg.webp';
import { faBriefcase, faShop, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faTiktok, faYoutube, faXTwitter } from '@fortawesome/free-brands-svg-icons';

// Components
import CardButton from '../../UI/CardButton/CardButton';
import CardIconButton from '../../UI/CardIconButton/CardIconButton';

// MUI
import { Link, Typography, styled } from '@mui/material';

const ContainerStyled = styled('div')(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  width: "100vw",

  "&::before": {
    content: '""',
    display: "block",
    backgroundAttachment: "scroll",
    height: "100vh",
    left: 0,
    pointerEvents: "none",
    position: "fixed",
    top: 0,
    transform: "scale(1)",
    width: "100vw",
    zIndex: 0,
    backgroundImage: "url("+bg+")",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));

const Card = styled('div')(({ theme }) => ({
  position: "relative",
  width: 380,
  maxWidth: "calc(100% - 2rem)",
  textAlign: "center",
  zIndex: 1,
  backgroundColor: "#FFFFFF",
  boxShadow: "0rem 1.75rem 4rem 0rem rgba(0,0,0,0.09)",
  borderRadius: "0.75rem",
  border: "solid 1px #E8E8E8",
  padding: "2rem 2rem 0 2rem",
  // Animation
  opacity: 0,
  transform: "scale(0.90)",
  transition: "opacity 0.625s ease 0.125s,transform 0.625s ease 0.125s",
  "&.Show": {
    opacity: 1,
    transform: "scale(1)",
  },
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  color: "#8c4ba4",
  letterSpacing: "0.05rem",
  width: "calc(100% + 0.05rem)",
  fontSize: "2.375em",
  lineHeight: 1.5,
  fontWeight: 300,
}));

const Home = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, [])
  
  return (
    <ContainerStyled>
      <Card className={show ? "Show" : ""}>
        <img src={logo} alt="Logo" style={{width: 92, height: 92}}/>
        <CardTitle>Marynn Art</CardTitle>
        <div style={{marginTop: 32, marginBottom: 32}}>
          <CardButton endIcon={<FontAwesomeIcon icon={faShop}/>} href="https://marynnart.etsy.com">Etsy shop</CardButton>
          <CardButton endIcon={<FontAwesomeIcon icon={faShoppingCart}/>} href="https://marynnart.wixsite.com/marynnart/comissions">Commissions</CardButton>
          <CardButton endIcon={<FontAwesomeIcon icon={faBriefcase}/>} href="https://marynnart.wixsite.com/marynnart">Portfolio</CardButton>
          <CardButton endIcon={<FontAwesomeIcon icon={faYoutube}/>} href="https://www.youtube.com/@marynn_art">Youtube</CardButton>
        </div>
        <hr style={{borderColor: "#ddd"}}/>
        <div style={{maxWidth: "80%", margin: "24px auto 24px auto"}}>
          <Typography variant="body" color="#8c4ba4" component="p">
            <span>Hello! I'm Mary Ann, don't hesitate to <Link href='mailto:marynnart.shop@gmail.com' color="#8c4ba4" style={{fontWeight: "bold"}}>email me</Link>{" <3"}</span>
          </Typography>
        </div>
        <div style={{marginBottom: 16}}>
          <CardIconButton target="_blank" rel="noreferrer" href="https://www.instagram.com/marynn_art/"><FontAwesomeIcon icon={faInstagram}/></CardIconButton>
          <CardIconButton target="_blank" rel="noreferrer" href="https://www.tiktok.com/@marynn_art?lang=en"><FontAwesomeIcon icon={faTiktok}/></CardIconButton>
          <CardIconButton target="_blank" rel="noreferrer" href="https://x.com/marynn_art"><FontAwesomeIcon icon={faXTwitter}/></CardIconButton>
          <CardIconButton target="_blank" rel="noreferrer" href="https://www.youtube.com/@marynn_art"><FontAwesomeIcon icon={faYoutube}/></CardIconButton>
        </div>
      </Card>
    </ContainerStyled>
  );
}

export default Home;