import React from 'react';
import ReactDOM from 'react-dom/client';

// Styles
import './index.css';

// Components
import App from './App';

// Material UI Theme
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from "@mui/material"

const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto Condensed',
      'sans-serif',
      '-apple-system',
    ].join(','),
  },
  palette: {
    mode: 'light',
    cardButton: {
      light: "#e1cee8",
      main: "#e1cee8",
      dark: "#572f66",
      contrastText: "#8c4ba4",
    },
    iconCardButton: {
      light: "#e1cee8",
      main: "#8c4ba4",
      dark: "#572f66",
      contrastText: "#572f66",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <App/>
      </CssBaseline>
    </ThemeProvider>
  </React.StrictMode>
);